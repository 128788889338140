<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <img
        src="../../../public/logo/mobilenew.png"
        class=""
        style="width: 200px"
      />
      <CRow class="justify-content-center">
        <CCol md="12">
          <CCardGroup>
            <CCard class="">
              <CCardBody>
                <div class="text-dark bg-white text-left p-3">
                  <h3>Agreement for Terms of Use </h3> <br>
                  <h4>1. Legal Notices </h4>
                  <p>
                    All information, materials, data and electronic files of any kind whatsoever (collectively, the "MATERIALS") contained and posted on this ME-Connect (the "App") are provided by Mitsubishi Electric India Pvt Ltd, for informational purposes only and as a service to its customers and vendors to assist in their business dealings with Mitsubishi Electric India Pvt Ltd – CNC Section. The MATERIALS are governed by and subject to the terms and conditions stated in these terms of use (the "Terms of Use"). Mitsubishi Electric India Pvt Ltd reserves the right to update the Terms of Use at any time without notice to YOU and may also make improvements and/or changes in the products and/or services described on the Site at any time without notice. By accessing, using or downloading any of the MATERIALS contained in this App, YOU (an individual acting on behalf of yourself or a business entity) agree to be bound by these Terms of Use. If YOU do not agree to be bound by these Terms of Use, do not Login, download, or use any MATERIALS from and immediately discontinue using this App and the MATERIALS. 
                  </p>

                  <h4>
                    2. Ownership of Copyright and Other Intellectual Property Rights of Product. 
                  </h4>
                  <p>
                    Users confirm in advance that Company retains all intellectual property rights, including copyrights, related to Product. 
                    Company grants a license to Users to use Product on the condition that Users accept and comply with this Agreement. However, this does not mean that the copyright and other intellectual property rights related to Product is transferred to Users.  
                  </p>

                  <h4>3. User Accounts </h4>
                  <ol>
                    <li>
                      Users are able to register as users to use Product within the scope of the number of users specified in this Agreement which is subject to approval by Mitsubishi Electric India Pvt Ltd. 
                    </li>
                    <li>
                      Product is only used by one person under one User Account and is not able to be used by more than one person under the same User Account.  
                    </li>
                  </ol>

                  <h4>
                    4. Prohibited Matters
                  </h4>
                    Company prohibits User from any of the following acts:
                  <ol>
                  <li>Duplication of all or part of Product and distribution of such duplicates.</li>
                  <li>Altering, combining, reverse engineering, disassembling, decompiling, or analysing Product. </li>
                  <li>The act of transferring, lending, or sublicensing copies or modifications of Product, or any other similar acts. </li>
                  <li>Reporting false information at the time of user registration. </li>
                  <li>Acts that damage the reputation or credibility of Company. </li>
                  <li>Criminal acts or acts against public order and morals by using Product. </li>
                  <li>Use of Company's name without permission. </li>
                  <li>Any other actions that cause serious damage to Company. </li>
                  </ol>
                  
                  <h4>5. Conclusion of Agreement for Use of Product, etc.</h4>
                  <ol>
                    <li>
                      The Agreement for the use of Product is deemed to be concluded when Users approved this Agreement digitally to Company. When Users approved this Agreement, Company deems that Users has accepted the contents of this Agreement.
                    </li>
                    <li>
                      Notwithstanding the provisions of the preceding items, Company does not conclude a contract for use of Product or a contract for change of use if Users fall under any of the following items. 
                      <ul style="list-style-type:none;">
                        <li>(1) Users are in default of its financial obligations with respect to the fees for use of Product. </li>
                        <li>(2) If there are any false statements, errors, or omissions in this Agreement.  </li>
                        <li>(3) In any other cases where Company deems inappropriate. </li>
                        <li>(4) In case the existing user left the organisation under any circumstances. </li>
                      </ul>
                      
                    </li>
                  </ol>
                  <h4>6. Maintenance </h4>
                  <p>Users agree in advance that Product is subject to specification changes or version upgrades without notice in order to maintain and improve the quality of Product. </p>
                  
                  <h4>7. Disclaimer</h4>
                  <div>
                    Company is not obligated to provide services beyond those specified in this Article. Although Company pays the most up-to-date attention to the work, Company is not liable for any damages to Users, unless such damages are caused by Company's intentional or gross negligence. 
                  </div>
                  <ol>
                    <li>
                      Company is liable for damages caused to Users due to the use or inability to use Product only in cases where Company is willful or grossly negligent, up to the limit of the usage fee paid. 
                    </li>
                    <li>
                      The damages set forth in the preceding paragraph exclude User's lost profits due to business interruption or loss of business information, damage to products, databases, hardware, networks, etc. other than the Product, or other special or indirect damages.
                    </li>                    
                  </ol>
                  <h4>
                    8. Setting and Maintenance of Facilities for Use of Product 
                  </h4>
                  <ol>
                    <li>
                     Users at User's own expense and responsibility, set up the User Equipment under the conditions specified by Company and maintain Users Equipment and the environment for use of Product. 
                    </li>
                    <li>
                     Users at its own responsibility and expense, connect the User Equipment to the Internet using the telecommunications services of a telecommunications carrier, etc. when using Product. 
                    </li>
                    <li>
                      Company is not obligated to provide Product to Users in the event that User's equipment, the Internet connection specified in the preceding paragraph, or the environment for using Product is defective.  
                    </li>
                    <li>
                      In the event that Company deems it necessary for the maintenance, operation, or technical aspects of Product, Company is entitled to take necessary actions such as monitoring, analysis, and investigation of the data, etc. provided or transmitted by Users, etc. in Product. 
                    </li>
                  </ol>
                  <h4>9. Privacy and Personal Information </h4>
                  <ol>
                    <li>
                      Company's approach to information protection is as described in Company's Privacy Policy. This policy explains how we handle your personal information and protect your privacy when you use Product.  
                    </li>
                    <li>
                      Users agree that any personal information you provide is used in accordance with our Privacy Policy.
                    </li>
                  </ol>

                  <h4>10. Procedures for Requesting Disclosure, etc. </h4>
                    <div>
                      Users can request Company to disclose User's personal data in accordance with the "Act on the Protection of Personal Information". In the unlikely event that the content of User's personal information is found to be untrue, Company will promptly correct or delete. 
                    </div> <br>
                 
                  <h4>
                    11. Confidentiality of User Account Information and Prohibition of Unauthorized Use 
                  </h4>
                    <div>
                      Users do not disclose or leak any and all information regarding User account to any third party, regardless of whether it is intentional or negligent, and regardless of whether it is before or after the termination of service use. In addition, any unauthorized use of the user account in violation of this agreement is strictly prohibited.  
                    </div> <br>

                  <h4>12. Unregulated Matters</h4>
                    <div>
                    Users and Company comply with these Terms of Use, and in the event that any matter not stipulated in these Terms of Use arises, or if any question arises regarding the interpretation of any matter in these Terms of Use, Users and Company consult in good faith to resolve the matter.  
                    </div> <br>
                  <h4>13. Agreed Jurisdiction</h4>
                    <div>
                    The district court having jurisdiction over the location of Company's head office have exclusive jurisdiction in the first instance over any disputes arising in connection with this Agreement despite discussions.   
                    </div> <br>
                </div>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
export default {
  name: "TermsAndConditions",
};
</script>
